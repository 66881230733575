import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  InputBase,
  Radio,
  RadioGroup,
  Typography
} from '@mui/material';
import * as React from 'react';
import { isEmpty } from 'lodash';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';

import { useCart, useEMenuAuth } from '../../store';
import { FullScreenLoader } from '../../components';
import { imageFallback, useSweetAlert } from '../../utils';
import {
  Selection,
  useGetAllDineInSectionsQuery,
  useCreateEMenuOrderMutation
} from '../../generated/graphql';
import { ExtraItemDrawer } from '../../components/ExtraItemDrawer';
import { CustomButton, HeaderLayout, Layout, Paper } from '../InRoomDining';

interface FoodItemCardProps {
  item: any;
  index: number;
  currency: string;
}
type ExtraOptions = {
  name: string;
  price: string;
};

type Cart = {
  id: string;
  count: number;
  extra: ExtraOptions[];
  selections: Selection[];
};
export const FoodItemCard = ({ item, index, currency }: FoodItemCardProps) => {
  const { name, price, img, isVegan } = item;

  const [open, setOpen] = React.useState(false);
  const cart = useCart((state: any) => state.cart);
  const addToCartIndex = useCart((state: any) => state.addToCartIndex);
  const removeFromCartIndex = useCart(
    (state: any) => state.removeFromCartIndex
  );
  const updateCartItemIndexExtra = useCart(
    (state: any) => state.updateCartItemIndexExtra
  );

  const currentItemInCart = cart[index];
  const itemsInCart = currentItemInCart.count;
  const extraItems = currentItemInCart?.extra;
  const selections = currentItemInCart?.selections;

  const toggleDrawer = () => setOpen(state => !state);

  const updateCartItemExtra = (extra: Array<any>, selections: Selection[]) =>
    updateCartItemIndexExtra(index, extra, selections);

  return (
    <Paper
      sx={{
        mt: 2,
        padding: 1.5,
        display: 'flex'
      }}
    >
      {img && (
        <Box
          src={img}
          alt={name}
          width={70}
          height={70}
          component="img"
          onError={imageFallback}
          style={{ borderRadius: '10px', objectFit: 'cover' }}
        />
      )}
      <Box sx={{ ml: 1.2, flex: 1 }}>
        <Typography
          variant="body2"
          sx={{ fontWeight: 600 }}
          color={'text.tile'}
        >
          {name}
        </Typography>
        <Box>
          {extraItems &&
            extraItems.map((extra: ExtraOptions) => {
              return (
                <Typography
                  variant="body2"
                  sx={{ color: 'text.secondary', display: 'inline' }}
                >
                  {extra?.name + ', '}
                </Typography>
              );
            })}
          {selections?.map((select: Selection) => {
            return (
              <>
                <Typography variant="body2" fontSize="15px" color={'text.tile'}>
                  {select.name}
                </Typography>
                <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                  {select?.selection_items.map((items: any) => {
                    return (
                      <>
                        {index === select?.selection_items.length - 1 ? (
                          <Typography sx={{ pr: 1 }} color={'text.tile'}>
                            {items.name}
                          </Typography>
                        ) : (
                          <Typography sx={{ pr: 1 }} color={'text.tile'}>
                            {items?.name},
                          </Typography>
                        )}
                      </>
                    );
                  })}
                </Box>
              </>
            );
          })}
        </Box>

        {/* {extraItems && <Typography variant="body2">{extraItems}</Typography>} */}
        {(extraItems || selections) && (
          <Button variant="text" sx={{ p: 0 }} onClick={toggleDrawer}>
            Customize
          </Button>
        )}
        <Typography variant="body2" sx={{ mt: 0.5 }} color={'text.tile'}>
          {currency} {price}
        </Typography>

        <Box
          component="img"
          onError={imageFallback}
          style={{ marginTop: '4px' }}
          alt={isVegan ? 'veg' : 'non-veg'}
          src={`/assets/${isVegan ? 'veg' : 'non-veg'}.png`}
        />
      </Box>
      {!Boolean(itemsInCart) ? (
        <CustomButton
          size="small"
          variant="outlined"
          sx={{ alignSelf: 'center' }}
          onClick={() => addToCartIndex(index)}
        >
          Add
        </CustomButton>
      ) : (
        <ButtonGroup
          size="small"
          variant="contained"
          sx={{
            border: 'none',
            color: '#ffffff',
            alignSelf: 'center',
            backgroundColor: 'primary.main'
          }}
        >
          <IconButton
            size="small"
            onClick={() => removeFromCartIndex(index)}
            sx={{ color: '#ffffff' }}
          >
            <RemoveIcon fontSize="inherit" />
          </IconButton>
          <CustomButton
            size="small"
            disableRipple
            disableFocusRipple
            disableTouchRipple
            sx={{ border: 'none !important' }}
          >
            {itemsInCart}
          </CustomButton>
          <IconButton
            size="small"
            onClick={() => addToCartIndex(index)}
            sx={{ color: '#ffffff' }}
          >
            <AddIcon fontSize="inherit" />
          </IconButton>
        </ButtonGroup>
      )}

      {(item?.extraOptions?.length !== 0 || item?.selections?.length !== 0) && (
        <ExtraItemDrawer
          open={open}
          currentItem={item}
          extraItems={extraItems}
          buttonText={'Update Cart'}
          toggleDrawer={toggleDrawer}
          selections={selections}
          updateCartItemExtra={updateCartItemExtra}
        />
      )}
    </Paper>
  );
};

function MyCart() {
  const { EMenu: data, property } = useOutletContext<any>();
  const currency = property?.currency;
  const propertyID = property?.id;
  const { EMenuID } = useParams();
  const sweetAlert = useSweetAlert();
  const navigate = useNavigate();
  const user = useEMenuAuth.getState().user;
  const { data: Sections, loading: SectionLoading } =
    useGetAllDineInSectionsQuery({
      fetchPolicy: 'network-only',
      variables: {
        propertyID: propertyID as string
      }
    });
  const AllSections = Sections?.getAllDineInSections;
  const cart = useCart((state: any) => state.cart);
  const [upsertOrder, { loading: loadingCreateOrder }] =
    useCreateEMenuOrderMutation();
  //   const [requestService] = usePropertyServicesMutationMutation();
  const resetCart = useCart((state: any) => state.resetCart);

  const extraOptions = cart
    ?.map((v: Cart) => {
      if (v?.extra) {
        return v?.extra;
      }
      return { price: '0' };
    })
    .flat();

  const total = extraOptions?.reduce(
    (acc: number, curr: ExtraOptions) => acc + parseFloat(curr?.price),
    0
  );

  function calculateTotalPrice(carts: Cart[]) {
    let totalPrice = 0;

    // Iterate over each selection in the cart
    for (const cart of carts) {
      if (!cart.selections) {
        continue;
      }
      for (const selection of cart.selections) {
        // Iterate over each item in the selection
        for (const item of selection.selection_items) {
          // Add the price of the item to the total
          totalPrice += item?.price || 0;
        }
      }
    }

    return totalPrice;
  }

  const selectionTotal = React.useMemo(() => {
    return calculateTotalPrice(cart);
  }, [cart]);

  // Computed states
  const totalItems = React.useMemo(
    () => cart.reduce((prev: number, current: any) => prev + current.count, 0),
    [cart]
  );

  const foodItemMap = React.useMemo(() => {
    if (!data?.items) return {};
    return data?.items?.reduce((acc: any, current: any) => {
      acc[current.id] = current;
      return acc;
    }, {});
  }, [data]);
  const cartItems = React.useMemo(() => {
    if (!data || !cart || !cart.length) return [];
    return cart.map((cartItem: any) => {
      const item = { ...foodItemMap[cartItem.id] };
      item.quantity = item.count;
      if (item.section && Array.isArray(item.section)) {
        const filteredSections = item.section.filter((sectionName: any) => {
          const section = AllSections?.find(
            section => section?.name === sectionName
          );
          if (
            isNaN(parseInt(section?.start_time?.split(':')[0] as string)) ||
            isNaN(parseInt(section?.end_time?.split(':')[0] as string))
          ) {
            return true;
          }
          if (section) {
            const CurrentDate = new Date();
            const startDate = new Date(CurrentDate.getTime());
            startDate.setHours(
              parseInt(section?.start_time?.split(':')[0] as string)
            );
            startDate.setMinutes(
              parseInt(section?.start_time?.split(':')[1] as string)
            );

            const endDate = new Date(CurrentDate.getTime());
            endDate.setHours(
              parseInt(section?.end_time?.split(':')[0] as string)
            );
            endDate.setMinutes(
              parseInt(section?.end_time?.split(':')[1] as string)
            );
            if (startDate > endDate) {
              startDate.setDate(startDate.getDate() - 1);
            }
            const now_utc = new Date(
              CurrentDate.getUTCFullYear(),
              CurrentDate.getUTCMonth(),
              CurrentDate.getUTCDate(),
              CurrentDate.getUTCHours(),
              CurrentDate.getUTCMinutes(),
              CurrentDate.getUTCSeconds()
            );
            return startDate <= now_utc && now_utc <= endDate;
          }

          return false;
        });
        if (filteredSections.length > 0) {
          return item;
        }
      }
      return [];
    });
  }, [foodItemMap, cart, data, AllSections]);

  const totalPrice = React.useMemo(() => {
    if (isEmpty(foodItemMap)) return 0;

    return cart.reduce((prev: number, current: any) => {
      const currentItem = foodItemMap[current.id];
      const itemCost = currentItem?.price * current.count;
      return prev + itemCost;
    }, 0);
  }, [cart, foodItemMap]);
  const [comment, setComment] = React.useState('');
  const [location, setLocation] = React.useState('');
  const [hotel, setHotel] = React.useState('');
  const [phone, setphone] = React.useState('');
  const [name, setName] = React.useState('');
  const handleOrder = async () => {
    let error;
    if (!phone) {
      error = 'Phone Number is required';
    }
    if (!location) {
      error = 'Room is required';
    }
    if (!hotel) {
      error = 'Tower is required';
    }
    if (!name) {
      error = 'Name is required';
    }
    if (error) {
      sweetAlert.fire({
        text: error,
        icon: 'error',
        timer: 2000
      });
      return;
    }

    try {
      const orderData = cart.map((item: any) => {
        const { name, price } = foodItemMap[item.id];

        return {
          quantity: item.count,
          dineInItem_name: name,
          dineInItem_price: price,
          dineInItem_id: item.id,
          ...(item.extra ? { extras: item.extra } : {}),
          ...(item.selections ? { selections: item.selections } : {})
        };
      });
      const orderInput = {
        cart_items: orderData,
        property_id: propertyID as string,
        external_guest_id: user?.id || phone,
        guest_id: 0,
        guest_name: user?.name || name,
        room_id: 0,
        total: totalPrice + total + selectionTotal,
        comment: comment,
        location: location,
        hotel: hotel,
        guest_phone: phone
      };

      const res = await upsertOrder({
        variables: {
          orderInput,
          id: EMenuID as string
        }
      });

      if (res.data?.upsertEMenuOrder?.code === '200') {
        sweetAlert
          .fire({
            text: 'Order Created Successfully',
            icon: 'success',
            timer: 2000
          })
          .then(() => {
            resetCart();
            navigate(`/e-menu/${EMenuID}`);
          });
      } else {
        sweetAlert.fire({
          text: res.data?.upsertEMenuOrder?.message || 'Order Not Created',
          icon: 'error'
        });
      }
    } catch (err) {
      sweetAlert.fire({
        text: `${err}`,
        icon: 'error'
      });
    }
  };

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setHotel(event.target.value);
  };

  return (
    <>
      {SectionLoading && <FullScreenLoader />}
      <Layout>
        <HeaderLayout title="View Cart" />

        {/* TODO: Make a separate hidden scroll component*/}
        {cartItems.length > 0 && (
          <>
            <Box
              sx={{
                padding: '5px',
                flex: 1,
                overflow: 'auto',
                scrollbarWidth: 'none',
                msOverflowStyle: 'none',
                '&::-webkit-scrollbar': { display: 'none' }
              }}
            >
              <Typography variant="body2">{totalItems} Items</Typography>

              {cartItems.map((item: any, index: number) => {
                return (
                  <FoodItemCard
                    key={index}
                    index={index}
                    item={item}
                    currency={currency}
                  />
                );
              })}
              <Box sx={{ mt: 2 }}>
                <FormControl>
                  {/* <FormLabel id="demo-radio-buttons-group-label">
                Therapist Preference
              </FormLabel> */}
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                    value={hotel}
                    onChange={handleChange}
                    row
                    sx={{
                      '& .MuiSvgIcon-root': {
                        fontSize: '20px'
                        // mt: 2
                        // color: 'rgba(178, 162, 121, 1)'
                      }
                    }}
                  >
                    <FormControlLabel
                      value="Tower 1"
                      control={<Radio />}
                      label="Tower 1"
                    />
                    <FormControlLabel
                      value="Tower 2"
                      control={<Radio />}
                      label="Tower 2"
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
              <Paper sx={{ mt: 2 }}>
                <InputBase
                  multiline
                  value={comment}
                  placeholder="Wish to add/remove an item your meal? Type here!"
                  onChange={e => setComment(e.target.value)}
                  sx={{
                    p: 1.5,
                    width: '100%',
                    color: 'text.tile',
                    borderRadius: 1.25
                  }}
                />
              </Paper>
              <Paper sx={{ mt: 2 }}>
                <InputBase
                  multiline
                  autoFocus
                  required
                  value={location}
                  placeholder="Add Your Room Number"
                  onChange={e => setLocation(e.target.value)}
                  sx={{
                    p: 1.5,
                    width: '100%',
                    color: 'text.tile',
                    borderRadius: 1.25
                  }}
                />
              </Paper>
              <Paper sx={{ mt: 2 }}>
                <InputBase
                  required
                  value={phone}
                  placeholder="Add Your Phone Number"
                  onChange={e => setphone(e.target.value)}
                  sx={{
                    p: 1.5,
                    width: '100%',
                    color: 'text.tile',
                    borderRadius: 1.25
                  }}
                />
              </Paper>
              <Paper sx={{ mt: 2 }}>
                <InputBase
                  required
                  value={name}
                  placeholder="Add Your Name"
                  onChange={e => setName(e.target.value)}
                  sx={{
                    p: 1.5,
                    width: '100%',
                    color: 'text.tile',
                    borderRadius: 1.25
                  }}
                />
              </Paper>
              <Paper sx={{ p: 2, mt: 3 }}>
                <Typography variant="h6" color={'text.tile'}>
                  Billing Summary
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography
                    variant="body2"
                    sx={{ mt: 1, color: 'text.secondary' }}
                  >
                    Total Items ({totalItems})
                  </Typography>

                  <Typography
                    variant="body2"
                    sx={{ mt: 1, fontWeight: 'bold' }}
                    color={'text.tile'}
                  >
                    {currency}{' '}
                    {(totalPrice + total + selectionTotal).toFixed(2)}
                  </Typography>
                </Box>

                {/* TODO: Set whether property want to set the tax and service cost values */}
                {/* <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography
                          variant="body2"
                          sx={{ mt: 0.5, color: "text.secondary" }}
                        >
                          Service Cost
                        </Typography>
          
                        <Typography variant="body2" sx={{ mt: 0.5, fontWeight: "bold" }}>
                          AED 0
                        </Typography>
                      </Box>
          
                      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography
                          variant="body2"
                          sx={{ mt: 0.5, color: "text.secondary" }}
                        >
                          Tax
                        </Typography>
          
                        <Typography variant="body2" sx={{ mt: 0.5, fontWeight: "bold" }}>
                          AED 0
                        </Typography>
                      </Box> */}

                <Divider sx={{ my: 1.5 }} />

                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    Total
                  </Typography>

                  <Typography
                    variant="body2"
                    sx={{ fontWeight: 'bold' }}
                    color={'text.tile'}
                  >
                    {currency}{' '}
                    {(totalPrice + total + selectionTotal).toFixed(2)}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    mt: 1
                  }}
                >
                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    *prices are exclusive of all taxes and government fees
                  </Typography>
                </Box>
              </Paper>
            </Box>

            <Box sx={{ p: 1 }}>
              <Button
                fullWidth
                variant="contained"
                onClick={handleOrder}
                disabled={totalItems === 0}
              >
                Make Order
              </Button>
            </Box>
          </>
        )}
      </Layout>

      {loadingCreateOrder && <FullScreenLoader />}
    </>
  );
}

export default MyCart;
