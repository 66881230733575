import {
  Box,
  Button,
  ButtonGroup,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  SxProps,
  TextField,
  Theme,
  Typography
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import React, { useState } from 'react';
import { ServiceRequestIcon } from '../../components';
import { CustomButton } from '../InRoomDining';
import useTheme from '@mui/material/styles/useTheme';
import useQuickService from './quick-service-hooks';
import 'react-datepicker/dist/react-datepicker.css';
import { QuickService } from '../../generated/graphql';
import {
  LocalizationProvider,
  MobileDateTimePicker
} from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import dayjs from 'dayjs';
import { useAuth } from '../../store';

interface QuickServicesProps {
  sx?: SxProps<Theme>;
  name: string;
}
const QuickServiceDialog = ({ sx, name }: QuickServicesProps) => {
  const {
    onSubmitService,
    tasks,
    notes,
    setNotes,
    open,
    setOpen,
    serviceCount,
    setServiceCount,
    selectedDate,
    handleDateChange,
    handleClose
  } = useQuickService();
  const guest = useAuth((s: any) => s?.guest);
  const theme = useTheme();
  const [selectedService, setSelectedService] = useState<QuickService | null>(
    null
  );
  const canAddMultiple = Boolean(selectedService?.qty_by_guest);
  const handleClickOpen = (service: any) => {
    setSelectedService(service);
    setOpen(true);
  };
  return (
    <>
      <Box className="ServiceRequest" sx={{ px: 2 }}>
        <Box sx={{ ...(sx ? sx : {}) }}>
          <Typography variant="h6" sx={{ mb: 1, mt: 3 }}>
            {name}
          </Typography>
          {tasks?.map((service: any, index: number) => {
            return (
              <Chip
                style={{ fontSize: '14px' }}
                key={service?.id}
                color="primary"
                variant="filled"
                label={service?.task}
                sx={{
                  mt: 1,
                  ml: 1
                }}
                onClick={() => handleClickOpen(service)}
              />
            );
          })}
        </Box>
      </Box>

      <Dialog open={open} onClose={handleClose}>
        {/* <DialogTitle>Request Service</DialogTitle> */}
        <DialogContent>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <ServiceRequestIcon
              sx={{ height: 60, width: 60 }}
              customColor={theme.palette.primary.main}
            />
            <Typography
              variant="h6"
              sx={{
                mt: 2,
                fontWeight: 600,
                textAlign: 'center',
                fontSize: theme => theme.spacing(2.25)
              }}
            >
              Make request for service {selectedService?.task}
            </Typography>
          </div>
          <TextField
            label="Notes"
            variant="outlined"
            fullWidth
            margin="normal"
            value={notes}
            onChange={e => setNotes(e.target.value)}
          />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DateTimePicker']}>
              <MobileDateTimePicker
                label="Select Date & Time"
                value={selectedDate === null ? null : dayjs(selectedDate)}
                onChange={handleDateChange}
                minDate={dayjs()}
                maxDate={dayjs(`${guest?.guest_departure}`)}
              />
            </DemoContainer>
          </LocalizationProvider>

          {canAddMultiple && (
            <ButtonGroup
              size="small"
              variant="contained"
              sx={{
                mt: 2,
                border: 'none',
                color: '#ffffff',
                alignSelf: 'center',
                backgroundColor: 'primary.main'
              }}
            >
              <IconButton
                size="small"
                onClick={() => setServiceCount(value => value + 1)}
                sx={{ color: '#ffffff' }}
              >
                <AddIcon fontSize="inherit" />
              </IconButton>
              <CustomButton
                size="small"
                disableRipple
                disableFocusRipple
                disableTouchRipple
                sx={{ border: 'none !important' }}
              >
                {serviceCount}
              </CustomButton>

              <IconButton
                size="small"
                sx={{ color: '#ffffff' }}
                onClick={() =>
                  setServiceCount(value => (value >= 2 ? value - 1 : value))
                }
              >
                <RemoveIcon fontSize="inherit" />
              </IconButton>
            </ButtonGroup>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} sx={{ flex: 1 }}>
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => onSubmitService(selectedService!.id)}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default QuickServiceDialog;
