import { createContext, useContext, useMemo, useState } from 'react';
import { Language } from '../generated/graphql';

type Props = {
  children?: React.ReactNode;
};

type ContextType = {
  language: Language;
  changeLanguage: (lang: Language | null) => void;
};

const LanguageContext = createContext({} as ContextType);

export function LanguageProvider({ children }: Props) {
  const [language, setLanguage] = useState<Language>(() => {
    const storedLang = localStorage.getItem('preferredLanguage');
    return storedLang
      ? JSON.parse(storedLang)
      : { code: 'en', name: 'English' }; // Default fallback
  });

  const changeLanguage = (lang: Language | null) => {
    if (!lang) return;
    const cleanedLang = {
      code: lang.code,
      name: lang.name
    };
    setLanguage(cleanedLang);
    localStorage.setItem('preferredLanguage', JSON.stringify(cleanedLang)); // Optional: Persist it
  };

  const value = useMemo(() => ({ language, changeLanguage }), [language]);

  return (
    <LanguageContext.Provider value={value}>
      {children}
    </LanguageContext.Provider>
  );
}

export function useLanguage() {
  return useContext(LanguageContext);
}
