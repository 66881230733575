import * as React from 'react';
import {
  useGetAllDineInSectionsQuery,
  useGetAllergensQuery,
  useGetDineInSectionsQuery,
  useGetGuestDineInCategoriesQuery,
  DineInItem,
  useGuestOrdersQuery
} from '../../generated/graphql';
import { groupBy, isEmpty } from 'lodash';
// import useHotelService from '../HotelSer/hotel-ser-hooks';
import { useOutletContext } from 'react-router-dom';
import { useEMenuAuth } from '../../store';
import { Cache } from 'aws-amplify';

export const useMenuItem = () => {
  const { EMenu: data, property } = useOutletContext<any>();

  const { data: Categories, loading: CategoriesLoading } =
    useGetGuestDineInCategoriesQuery({
      fetchPolicy: 'network-only',
      variables: {
        propertyID: property?.id as string
      }
    });

  const { data: Sections, loading: SectionLoading } = useGetDineInSectionsQuery(
    {
      fetchPolicy: 'network-only',
      variables: {
        propertyID: property?.id as string
      }
    }
  );
  const { data: AllSections, loading: AllSectionLoading } =
    useGetAllDineInSectionsQuery({
      fetchPolicy: 'network-only',
      variables: {
        propertyID: property?.id as string
      }
    });
  const { data: Allergens } = useGetAllergensQuery({
    fetchPolicy: 'network-only',
    variables: {
      propertyID: property?.id as string
    }
  });

  const allergenName: { [key: string]: string } = {};
  Allergens?.getAllergens?.forEach(
    allergen => (allergenName[allergen?.icon || ''] = allergen?.name || '')
  );

  return {
    data,
    loading: CategoriesLoading || SectionLoading || AllSectionLoading,
    Categories: Categories?.getGuestDineInCategories,
    Sections: Sections?.getDineInSections,
    AllSections: AllSections?.getAllDineInSections,
    ALLERGEN_NAMES: allergenName,
    AllergensList: Allergens?.getAllergens,
    currency: property?.currency,
    property
  };
};

const useEMenu = () => {
  const ALL_MENU = 'ALL_MENU';
  const { data, loading, Categories, ALLERGEN_NAMES, currency, property } =
    useMenuItem();
  const [currentCategory, setCurrentCategory] = React.useState('ALL_MENU');
  const [search, setSearch] = React.useState<string>('');
  const [openDialog, setOpenDialog] = React.useState(false);
  const [dialogData, setDialogData] = React.useState<DineInItem | null>(null);
  const user =
    useEMenuAuth.getState().userDetails || Cache.getItem('userDetails');
  const isAuthenticated = !isEmpty(user) ? true : false;
  const needsAuthentication = (data?.interactive && !isEmpty(data?.auth_method)) || false;
  const { data: orders, loading: loadingOrders } = useGuestOrdersQuery({
    fetchPolicy: 'no-cache',
    variables: {
      externalGuestID: user?.id as string,
      propertyID: property?.id
    },
    skip: !user
  });
  const handleOpen = (data: DineInItem) => {
    setDialogData(data);
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
    setDialogData(null);
  };
  //   let sortedSection = [...(Sections || [])]?.sort(
  //     (a, b) => (a?.sequence || 0) - (b?.sequence || 0)
  //   );
  //   const { diningType } = useHotelService();
  //   const [foodMenu, setFoodMenu] = React.useState<any[]>([]);

  //   React.useMemo(() => {
  //     if (!data) return { categories: [], foodMenu: [] };
  //     let foodMenuItems = [];
  //     if (diningType === 'Category') {
  //       const groupedMenu = groupBy(
  //         data?.getEMenu?.items,
  //         (data: any) => data.type
  //       );
  //       foodMenuItems =
  //         Categories?.map(category => {
  //           return {
  //             description: category?.desc,
  //             name: category?.name,
  //             items: groupedMenu[category?.name || '']
  //           };
  //         }) || [];
  //     } else {
  //       let iterable: { [key: string]: any } = {};
  //       let edibleLength = data?.getEMenu?.items?.length || 0;
  //       let SectionLength = Sections?.length || 0;
  //       for (let i = 0; i < edibleLength; i++) {
  //         for (let j = 0; j < SectionLength; j++) {
  //           let SectionName = Sections?.[j]?.name;
  //           if (
  //             data?.getEMenu?.items?.[i]?.section?.includes(SectionName || '')
  //           ) {
  //             if (SectionName) {
  //               if (iterable[SectionName]) {
  //                 iterable[SectionName].push(data?.getEMenu?.items?.[i]);
  //               } else {
  //                 iterable[SectionName] = [data?.getEMenu?.items?.[i]];
  //               }
  //             }
  //           }
  //         }
  //       }
  //       foodMenuItems =
  //         Sections?.map(section => {
  //           return {
  //             description: section?.desc,
  //             name: section?.name,
  //             items: iterable[section?.name || '']
  //           };
  //         }) || [];
  //     }
  //     setFoodMenu(foodMenuItems);
  //   }, [data, Categories, Sections, diningType]);

  //   function findDefaultCategory(category: any) {
  //     for (let i = 0; i < sortedSection?.length; i++) {
  //       if (
  //         sortedSection[i]?.name === category &&
  //         sortedSection[i]?.default_category
  //       ) {
  //         return sortedSection[i]?.default_category;
  //       }
  //     }
  //     return [...(Categories || [])]?.sort(
  //       (a, b) => (a?.sequence || 0) - (b?.sequence || 0)
  //     )[0]?.name;
  //   }

  const categoryList = React.useMemo(() => {
    return (
      [...(Categories || [])]
        ?.filter(category => category?.name !== 'Uncategorized')
        ?.sort((a, b) => (a?.sequence || 0) - (b?.sequence || 0))
        ?.map(section => section?.name as string) || ([] as string[])
    );
  }, [Categories]);

  const groupedFilteredItems = React.useMemo(() => {
    const foodMenu = data?.items;
    const groupedMenu = groupBy(foodMenu, 'type');
    if (currentCategory === ALL_MENU) {
      return search.length > 0
        ? Object.fromEntries(
            Object.entries(groupedMenu).map(([key, value]) => [
              key,
              value.filter(item =>
                item?.name?.toLowerCase().includes(search?.toLowerCase())
              ) || []
            ])
          )
        : groupedMenu;
    } else {
      return search.length > 0
        ? {
            [currentCategory]:
              groupedMenu[currentCategory]
                ?.sort((a, b) => (a?.sequence || 0) - (b?.sequence || 0))
                ?.filter(item =>
                  item?.name?.toLowerCase().includes(search?.toLowerCase())
                ) || []
          }
        : {
            [currentCategory]:
              groupedMenu[currentCategory]
                ?.sort((a, b) => (a?.sequence || 0) - (b?.sequence || 0))
                ?.filter(item =>
                  item?.name?.toLowerCase().includes(search?.toLowerCase())
                ) || []
          };
    }
  }, [currentCategory, search, data?.items]);

  //   const sectionList = React.useMemo(() => {
  //     return (
  //       sortedSection?.map(section => section?.name as string) || ([] as string[])
  //     );
  //   }, [sortedSection]);

  return {
    loading: loadingOrders || loading,
    orders,
    // Sections: sectionList,
    categories: categoryList,
    Emenu: data,
    ALLERGEN_NAMES,
    groupedFilteredItems,
    currentCategory,
    setCurrentCategory,
    search,
    setSearch,
    openDialog,
    handleOpen,
    handleClose,
    dialogData,
    currency,
    isAuthenticated,
    needsAuthentication
    // defaultCategory: findDefaultCategory
  };
};

export default useEMenu;
