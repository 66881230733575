import { ApolloProvider } from '@apollo/client';

import { ThemeConfig } from './theme';
import AppRouter from './view/AppRouter';
import apolloClient from './core/apolloClient';
import EMenuRouter from './view/E-MenuRouter';
import { LanguageProvider } from './context/LanguageContext';

function App() {
  const path = window.location.pathname;
  return (
    <ApolloProvider client={apolloClient}>
      <ThemeConfig>
        <LanguageProvider>
          {path.startsWith('/e-menu') ? <EMenuRouter /> : <AppRouter />}
        </LanguageProvider>
        {/* <AppRouter /> */}
      </ThemeConfig>
    </ApolloProvider>
  );
}

export default App;
