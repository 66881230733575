import {
  Box,
  CardActions,
  CardContent,
  Chip,
  Typography
  // chipClasses
} from '@mui/material';
// import { imageFallback } from '../../utils';
import {
  FeedbackAndPromotions,
  InfoCard,
  Slider,
  SliderCard
} from './components';
import { Drawer, ServiceCard } from '../../components';
// import { useNavigate } from 'react-router-dom';
import useHotelInfo from '../HotelInfo/hotel-info-hooks';
import { startCase } from 'lodash';
import EmailIcon from '@mui/icons-material/Email';
import WifiCalling3Icon from '@mui/icons-material/WifiCalling3';
import RichTextContent from '../../components/RTEContent';
import { useState } from 'react';
import { About } from '../../generated/graphql';
function NotVerified() {
  const {
    about,
    dir_con,
    phoneNumber,
    propertyDetails,
    hotelRoomsImage,
    directory_description,
    hotelDiningImage,
    isActiveDirectory,
    isActiveHotel_rooms,
    isActiveAbout,
    isActiveHotel_services,
    isActiveDining,
    isActiveArtNdesign,
    isActiveNeighbourhood,
    isActiveEvents,
    isActiveSafetyNsecurity,
    isActiveLocation,
    isActiveRewards,
    artNdesignImg,
    neighbourhoodImg,
    eventsImg,
    safetyNsecurityImg,
    locationImg,
    rewardsImg,
    directionImage,
    hotelServicesImage,
    loadingMeta,
    artNdesign,
    neighbourhood,
    events,
    safetyNsecurity,
    location,
    rewards
  } = useHotelInfo();
  // const navigate = useNavigate();
  type ExtendedAbout = About & {
    name: string;
  };
  const [open, setOpen] = useState(false);
  const [cardData, setCardData] = useState<ExtendedAbout | null>(null);
  function handleOpen() {
    setOpen(true);
  }
  function handleClose(): void {
    setOpen(false);
    setCardData(null);
  }
  function onCardClick(card: any) {
    setCardData(card);
    handleOpen();
  }
  return (
    <Box
      sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        // overflow: 'auto',
        scrollbarWidth: 'none',
        msOverflowStyle: 'none',
        '&::-webkit-scrollbar': { display: 'none' }
      }}
    >
      {/* <Box
        sx={{
          px: 2,
          display: 'flex',
          fontWeight: 600,
          alignItems: 'center',
          justifyContent: 'center',
          fontSize: theme => theme.spacing(2.5)
        }}
      >
        Hello
        <Box
          width={36}
          height={36}
          alt="Emoji"
          component="img"
          onError={imageFallback}
          src="/assets/hand-emoji.png"
          sx={{ ml: 0.5, verticalAlign: 'bottom' }}
        />
        <Chip
          variant="filled"
          color="primary"
          label="Chat With Us"
          deleteIcon={<ChatIcon />}
          onClick={() => navigate('/chat')}
          onDelete={() => navigate('/chat')}
          sx={{ fontSize: theme => theme.spacing(1.75) }}
        />
      </Box> */}

      <InfoCard />
      {isActiveAbout && (
        <Box sx={{ p: 2 }}>
          <ServiceCard
            showInfo={true}
            image={about?.img as string}
            label={'About Us'}
            description={about?.desc as string}
            notVerified={true}
          >
            <CardContent sx={{ px: 2, pb: 0 }}>
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                sx={{
                  mb: 0.5,
                  fontWeight: 600,
                  fontSize: theme => theme.spacing(2.5)
                }}
              >
                {propertyDetails?.getPropertyByDomain &&
                  startCase(propertyDetails?.getPropertyByDomain?.name)}
              </Typography>
              {dir_con?.address?.toString().startsWith('<') ? (
                <RichTextContent content={dir_con?.address?.toString()} />
              ) : (
                <Typography
                  sx={{
                    color: 'text.secondary',
                    textDecorationLine: 'underline'
                  }}
                  variant="body2"
                >
                  {dir_con?.address}
                </Typography>
              )}
            </CardContent>
            <CardActions sx={{ px: 2, py: 2 }}>
              <Chip
                variant="filled"
                // color="primary"
                sx={{
                  flex: 1,
                  bgcolor: 'primary.main',
                  color: 'white'
                  // [`& .${chipClasses.icon}`]: {
                  //   color: 'white'
                  // }
                }}
                label={phoneNumber}
                icon={<WifiCalling3Icon fontSize="small" />}
                onClick={e => {
                  e.preventDefault();
                  phoneNumber && window.open(`tel:${phoneNumber}`);
                }}
              />
              <Chip
                variant="filled"
                // color="primary"
                label={dir_con?.email}
                sx={{
                  flex: 1,
                  overflow: 'hidden',
                  bgcolor: 'primary.main',
                  color: 'white'
                  // [`& .${chipClasses.icon}`]: {
                  //   color: 'white'
                  // }
                }}
                icon={<EmailIcon fontSize="small" />}
                onClick={() =>
                  dir_con?.email && window.open(`mailto:${dir_con.email}`)
                }
              />
            </CardActions>
          </ServiceCard>
        </Box>
      )}
      {isActiveHotel_rooms && (
        <Box sx={{ p: 2 }}>
          <ServiceCard
            showInfo={false}
            label={'Hotel Rooms'}
            path={`/hotel-rooms`}
            image={hotelRoomsImage as string}
            notVerified={true}
          />
        </Box>
      )}

      {isActiveDirectory && (
        <Box sx={{ p: 2 }}>
          <ServiceCard
            showInfo={true}
            image={dir_con?.img as string}
            description={directory_description as string}
            label={'Hotel Directory'}
            notVerified={true}
          />
        </Box>
      )}
      {isActiveHotel_services && (
        <Box sx={{ p: 2 }}>
          <ServiceCard
            showInfo={false}
            label={'Dining'}
            path={`/dining`}
            image={hotelDiningImage as string}
            notVerified={true}
          />
        </Box>
      )}

      {isActiveAbout ||
      isActiveDirectory ||
      isActiveDining ||
      isActiveHotel_services ||
      isActiveHotel_rooms ||
      isActiveArtNdesign ||
      isActiveNeighbourhood ||
      isActiveEvents ||
      isActiveSafetyNsecurity ? (
        <Slider
          title={'Our Hotel'}
          swipeText={'Swipe'}
          loading={loadingMeta}
          count={
            [
              isActiveAbout,
              isActiveDirectory,
              isActiveDining,
              isActiveHotel_services,
              isActiveHotel_rooms
            ].filter(Boolean).length // Filters out falsy values
          }
        >
          {isActiveAbout && (
            <SliderCard
              icon={about?.img}
              gettitle={'About Us'}
              onClick={() => onCardClick({ ...about, name: 'About Us' })}
              index={1}
            />
          )}
          {isActiveDirectory && (
            <SliderCard
              icon={directionImage}
              gettitle={'Direction'}
              link={'./hotel-information'}
              index={2}
            />
          )}
          {isActiveDining && (
            <SliderCard
              icon={hotelDiningImage}
              gettitle={'Dining'}
              link={`./hotel-information`}
              index={3}
            />
          )}
          {isActiveHotel_services && (
            <SliderCard
              icon={hotelServicesImage}
              gettitle={'Service'}
              link={'./hotel-information'}
              index={4}
            />
          )}
          {isActiveHotel_rooms && (
            <SliderCard
              icon={hotelRoomsImage}
              gettitle={'Rooms'}
              link={`./hotel-information`}
              index={5}
            />
          )}
          {isActiveArtNdesign && (
            <SliderCard
              icon={artNdesignImg}
              gettitle={'Art & Design'}
              onClick={() =>
                onCardClick({ ...artNdesign, name: 'Art & Design' })
              }
              index={6}
            />
          )}
          {isActiveNeighbourhood && (
            <SliderCard
              icon={neighbourhoodImg}
              gettitle={'Neighbourhood'}
              onClick={() =>
                onCardClick({ ...neighbourhood, name: 'Neighbourhood' })
              }
              index={7}
            />
          )}
          {isActiveEvents && (
            <SliderCard
              icon={eventsImg}
              gettitle={'Events'}
              onClick={() => onCardClick({ ...events, name: 'Events' })}
              index={8}
            />
          )}
          {isActiveSafetyNsecurity && (
            <SliderCard
              icon={safetyNsecurityImg}
              gettitle={'Safety'}
              onClick={() =>
                onCardClick({ ...safetyNsecurity, name: 'Safety' })
              }
              index={9}
            />
          )}
          {isActiveLocation && (
            <SliderCard
              icon={locationImg}
              gettitle={'Location'}
              onClick={() => onCardClick({ ...location, name: 'Location' })}
              index={10}
            />
          )}
          {isActiveRewards && (
            <SliderCard
              icon={rewardsImg}
              gettitle={'IHG 1 Rewards'}
              onClick={() => onCardClick({ ...rewards, name: 'Rewards' })}
              index={11}
            />
          )}
        </Slider>
      ) : (
        <></>
      )}

      <FeedbackAndPromotions hideFeedback={true} />
      <Drawer
        open={open}
        image={cardData?.img}
        name={(cardData?.name || '') as string}
        handleOpen={handleOpen}
        handleClose={handleClose}
        desc={cardData?.desc as string}
      />
    </Box>
  );
}

export default NotVerified;
