import React from 'react';
import { useTranslation } from '../../utils';
import { useLanguage } from '../../context/LanguageContext';

const RichTextContent = ({ content }: { content: string }) => {
  const { language } = useLanguage();
  const [textNodes, setTextNodes] = React.useState<string[]>([]);
  const [processedContent, setProcessedContent] = React.useState(content);
  React.useEffect(() => {
    if (!content) return;

    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = content;
    const extractedTextNodes: string[] = [];

    function extractTextNodes(node: ChildNode) {
      if (node.nodeType === Node.TEXT_NODE && node.nodeValue?.trim()) {
        extractedTextNodes.push(node.nodeValue);
      } else if (node.nodeType === Node.ELEMENT_NODE) {
        node.childNodes.forEach(extractTextNodes);
      }
    }

    extractTextNodes(tempDiv);
    setTextNodes(extractedTextNodes);
  }, [content]);

  // Use the translation hook
  const translations = useTranslation(textNodes, language.code);
  React.useEffect(() => {
    if (!textNodes.length || Object.keys(translations).length === 0) return;

    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = content;

    function replaceTextNodes(node: ChildNode) {
      if (node.nodeType === Node.TEXT_NODE && node.nodeValue?.trim()) {
        node.nodeValue = translations[node.nodeValue] || node.nodeValue;
      } else if (node.nodeType === Node.ELEMENT_NODE) {
        node.childNodes.forEach(replaceTextNodes);
      }
    }

    replaceTextNodes(tempDiv);
    setProcessedContent(tempDiv.innerHTML);
  }, [translations, content, textNodes.length]);

  // Modify the <img> tags to retain width/height but make them responsive
  const cleanedContent = processedContent.replace(
    /<img[^>]*\s(width|height)="[^"]*"[^>]*>/g,
    match => {
      // Extract the width and height values
      const widthMatch = match.match(/width="(\d+)"/);
      const heightMatch = match.match(/height="(\d+)"/);
      const width = widthMatch ? widthMatch[1] : 'auto';
      const height = heightMatch ? heightMatch[1] : 'auto';

      // Add responsive styles
      return match.replace(
        /<img/g,
        `<img style="max-width: 100%; height: auto; aspect-ratio: ${width} / ${height};" `
      );
    }
  );

  return (
    <div
      contentEditable="false"
      dangerouslySetInnerHTML={{ __html: cleanedContent }}
    />
  );
};

export default RichTextContent;
