import * as React from 'react';
import {
  Route,
  Routes,
  Navigate,
  BrowserRouter as Router,
  useParams,
  Outlet
} from 'react-router-dom';
import { useEMenuAuth } from '../store';
import VerifyUser from './Otp';
import { useDomain } from '../utils';
import { FullScreenLoader } from '../components';
import EMenu from './E-Menu';
import Cart from './E-Menu-Cart';
import OrderHistory from './E-Menu-OrderHistory';
import {
  useGetEMenuQuery,
  usePropertyDetailsQuery
} from '../generated/graphql';
import { Cache } from 'aws-amplify';
import { isEmpty } from 'lodash';
function AuthRoutes() {
  const { EMenuID } = useParams();
  const domainId = useDomain();
  const setEMenuGuest = useEMenuAuth((state: any) => state.setEMenuGuest);
  const { data: propertyData, loading: proprtyLoading } =
    usePropertyDetailsQuery({
      variables: {
        domain: domainId as string
      }
    });
  const { data, loading } = useGetEMenuQuery({
    variables: {
      propertyID: propertyData?.getPropertyByDomain?.id as string,
      id: EMenuID || ''
    },
    skip: !EMenuID
  });

  if (loading || proprtyLoading) return <FullScreenLoader />;

  const user = useEMenuAuth.getState().user || Cache.getItem('userDetails');
  if (!useEMenuAuth.getState().user && Cache.getItem('userDetails')) {
    setEMenuGuest(user);
  }
  const EMenu = data?.getEMenu;
  const needsAuthentication =
    (EMenu?.interactive && !isEmpty(EMenu?.auth_method)) || false;
  const isAuthenticated = !isEmpty(user) ? true : false;

  const property = propertyData?.getPropertyByDomain;
  if (needsAuthentication && !isAuthenticated) {
    return (
      <Navigate
        to={`/e-menu/${EMenuID}/otp`}
        state={{ auth: EMenu?.auth_method }}
        replace
      />
    );
  }

  return <Outlet context={{ EMenu, property }} />;
}

const EMenuRouter = () => {
  return (
    <Router>
      <Routes>
        <Route element={<AuthRoutes />}>
          <Route path="/e-menu/:EMenuID" element={<EMenu />} />
          <Route path="/e-menu/:EMenuID/cart" element={<Cart />} />
          <Route
            path="/e-menu/:EMenuID/order-history"
            element={<OrderHistory />}
          />
        </Route>
        <Route path="/e-menu/:EMenuID/otp" element={<VerifyUser />} />
      </Routes>
    </Router>
  );
};

export default EMenuRouter;
