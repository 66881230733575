import { useEffect, useState } from "react";

const CACHE_EXPIRY_HOURS = 24 * 5; // Cache expires in 24 * [days] hours
const STORAGE_KEY = "translationCache";

function setCache(text: string|null|undefined, targetLanguage:string, translatedText:string) {
    if(!text)return
    let cache = JSON.parse(localStorage.getItem(STORAGE_KEY)??"{}");
    if (!cache[targetLanguage]) cache[targetLanguage] = {};
    
    cache[targetLanguage][text] = {
      translatedText,
      expiresAt: new Date().getTime() + CACHE_EXPIRY_HOURS * 60 * 60 * 1000,
    };
  
    localStorage.setItem(STORAGE_KEY, JSON.stringify(cache));
  }
  
  function getCache(text:string|null|undefined, targetLanguage:string) {
    if(!text)return null
    let cache = JSON.parse(localStorage.getItem(STORAGE_KEY)??"{}");
    const cached = cache[targetLanguage]?.[text];
  
    if (cached && cached.expiresAt > Date.now()) {
      return cached.translatedText; // ✅ Return if not expired
    }
  
    return null; // ❌ Cache miss
  }
  

  export async function translateTexts(texts:(string | null | undefined)[], targetLanguage:string) {
    if (!targetLanguage || targetLanguage === "en") return texts;
    const cachedTranslations = texts.map((text) => getCache(text, targetLanguage));
  const textsToTranslate = texts.filter((_, index) => !cachedTranslations[index]).filter(t=>t!==null || t!==undefined);

  if (textsToTranslate.length === 0) return cachedTranslations;
  
    try {
      const response = await fetch(
        `https://translation.googleapis.com/language/translate/v2?key=${process.env.REACT_APP_GOOGLE_API_KEY}`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            q: textsToTranslate,
            target: targetLanguage,
            format: "text",
          }),
        }
      );
  
      const data = await response.json();
      const translatedTexts = data?.data?.translations.map((t: any) => t?.translatedText);
     // Store translations in cache
    textsToTranslate.forEach((text, index) => {
        setCache(text, targetLanguage, translatedTexts[index]);
      });
  
      // Merge cached and newly translated texts
      return texts.map((text, index) => cachedTranslations[index] || translatedTexts.shift());
    } catch (error) {
      console.error("Translation error:", error);
      return texts;
    }
  }

export function useTranslation(texts:(string | null | undefined)[], targetLanguage:string) {
  const [translated, setTranslated] = useState<Record<string, string>>({});
  
  useEffect(() => {
    let isMounted = true;

    async function fetchAllTranslations() {
        if (!texts.length || targetLanguage === "en") {
            setTranslated((prev) => {
              const newTranslations = Object.fromEntries(texts.map((t) => [t, t]));
              return JSON.stringify(prev) === JSON.stringify(newTranslations) ? prev : newTranslations;
            });
            return;
          }
  
        const translatedTexts = await translateTexts(texts, targetLanguage);
        if (isMounted) {
          const newTranslations = Object.fromEntries(texts.map((t, i) => [t, translatedTexts[i]]));
          
          setTranslated((prev) =>
            JSON.stringify(prev) === JSON.stringify(newTranslations) ? prev : newTranslations
          );
        }
      }

    fetchAllTranslations();

    return () => {
      isMounted = false;
    };
  }, [texts, targetLanguage]);

  return translated;
}
